











import { Component, Vue } from "vue-property-decorator";
import Screen from "@/app/modules/_global/classes/Screen";

@Component
export default class ContentLayout extends Vue {
  boundedContentHeight = 0;

  created() {
    this.$nextTick(() => {
      const screen = new Screen();
      this.boundedContentHeight = screen.getBoundedContentHeight();
    });
  }
}
